import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DownArrow from '../Icons/DownArrow';
import useScrollToTop from '../../service/UseScrollToTop';

export default function Navigation() {
  const [isNavOpen, setIsNavOpen] = useState(false); 
  const [activeMenu, setActiveMenu] = useState(''); 
  const location = useLocation();
  const scrollToTop = useScrollToTop();
  // Handle menu toggle for mobile view
  const toggleNav = () => {
    setIsNavOpen((prev) => !prev); // Toggle state
  };
  
  // Handle dropdown click and set the active menu
  const handleDropdownClick = (mainMenu) => {
    setActiveMenu(mainMenu);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });  
  };

  // Update active menu state when the location changes (this ensures active state is maintained on page load or route change)
  useEffect(() => {
    if (location.pathname.includes('/ultrasound-guided-injections')) {
      setActiveMenu('/ultrasound-guided-injections');
    } else {
      setActiveMenu(location.pathname);
    }
  }, [location.pathname]);

  return (
    <nav className="navbar navbar-expand-lg">
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarNav"
          aria-expanded={isNavOpen ? "true" : "false"}
          aria-label="Toggle navigation"
          onClick={toggleNav}
        >
           {isNavOpen ? (
            <span className="navbar-close">✖</span> 
            ) : (
              <span className="navbar-open">☰</span>
            )}
        </button>
        <div className={`navbar-collapse ${isNavOpen ? 'visible' : ''}`} id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li>             
              <Link 
                className={location.pathname === '/' ? 'active' : ''} 
                to="/" 
                onClick={scrollToTop}
              >
                Home
              </Link>
            </li>

            <li>
              <Link 
                className={location.pathname === '/minor-surgery' ? 'active' : ''} 
                to="/minor-surgery" 
                onClick={scrollToTop}
              >
                Minor Surgery
              </Link>
            </li>

            {/* Dropdown for Ultrasound Guided Injections */}
            {/* <li>
              <Link 
                className={activeMenu === '/ultrasound-guided-injections' ? 'active' : ''} 
                to="#"
                onClick={scrollToTop}
              >
                Ultrasound Guided Injections
                <DownArrow />
              </Link>

              <ul>
                <li>
                  <Link 
                    className={location.pathname === '/steroid-injection' ? 'active' : ''} 
                    to="/steroid-injection" 
                    onClick={() => handleDropdownClick('/ultrasound-guided-injections')}
                  >
                    Steroid Injection
                  </Link>
                </li>
                 <li>
                  <Link 
                    className={location.pathname === '/platelet-rich-fibrin' ? 'active' : ''} 
                    to="/platelet-rich-fibrin" 
                    onClick={() => handleDropdownClick('/ultrasound-guided-injections')}
                  >
                    Platelet Rich Fibrin
                  </Link>
                </li>
                <li>
                  <Link 
                    className={location.pathname === '/arthrosamid' ? 'active' : ''} 
                    to="/arthrosamid" 
                    onClick={() => handleDropdownClick('/ultrasound-guided-injections')}
                  >
                    Arthrosamid
                  </Link>
                </li> 
                <li>
                  <Link 
                    className={location.pathname === '/ostenil-plus' ? 'active' : ''} 
                    to="/ostenil-plus" 
                    onClick={() => handleDropdownClick('/ultrasound-guided-injections')}
                  >
                    Ostenil Plus
                  </Link>
                </li>
                <li>
                  <Link 
                    className={location.pathname === '/durolane' ? 'active' : ''} 
                    to="/durolane" 
                    onClick={() => handleDropdownClick('/ultrasound-guided-injections')}
                  >
                    Durolane
                  </Link>
                </li> 
              </ul>
            </li> */}

            <li>
              <Link 
                className={location.pathname === '/faq' ? 'active' : ''} 
                to="/faq" 
                onClick={scrollToTop}
              >
                FAQ
              </Link>
            </li>

             {/* <li>             
              <Link 
                className={location.pathname === '/pricing' ? 'active' : ''} 
                to="/pricing" 
                onClick={scrollToTop}
              >
                Pricing
              </Link>
            </li>  */}
          </ul>
           <Link onClick={scrollToTop} to="/contact-us" className="btn">Contact Us</Link>          
        </div>      
    </nav>
  );
}
